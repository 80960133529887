import { Link } from 'gatsby'
import React from 'react'
import Layout from '../components/layout'
import Nav from '../components/nav/nav'
import { FaAngleLeft } from "react-icons/fa"
import TrainingMapImg from '../images/human-capital-usecase/training.png'

const CapacityBuildingUnilever = () => {
    return (
        <div className="human-capital-usecase-details">
            <Layout>
                <Nav
                    isWhite={true}
                />

                <div className="px-5 pt-4">
                    <Link to="/human-capital-development-usecase"><p className="cursor black-link-text mb-0 my-auto"><span className="mr-1"><FaAngleLeft /></span>Human capital development</p></Link>
                </div>

                <div className="digital-usecase pb-5 pt-4">
                    <div className="bg-capacity-building"></div>
                </div>


                <div className="container">
                    <h1 className="font-weight-bold text-center mt-md-5 big-text">Training a workforce in all round organisational operations.</h1>

                    <p className="text-center mt-4 mb-4 pb-2 text-uppercase inner-page-breadcrumb"><span className="text-secondary mr-2">Capacity building</span>Insurance</p>

                    <div style={{ borderBottom: '5px solid #E41700' }}></div>
                </div>

                <div className="py-5 my-5"></div>

                <div className="bg-client-usecase">
                    <div className="container">
                        <div className="row pt-5 mt-5 pb-5">
                            <div className="col-md-6 my-5">
                                <p className="font-weight-bold" style={{ fontSize: '1.5rem' }}>The Client</p>

                                <p className="text-secondary" style={{ fontSize: '1.15rem', lineHeight: '30px' }}>
                                    Our client, a top-five insurance corporation in Nigeria, put their faith in our wealth of knowledge and expertise to train their workforce in the areas of FinTech, InsurTech, RegTech, and all-round organizational operations, and prepare them to take on the market.
                                </p>

                                <p className="text-secondary" style={{ fontSize: '1.15rem', lineHeight: '30px' }}>
                                    In addition, they solicited our help to develop a transparent learning system that would ensure continuous learning during the COVID-19 Pandemic.
                                </p>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-solution-usecase">
                    <div className="container">
                        <div className="row pt-5 mt-5 pb-5">
                            <div className="col-md-6 mx-auto my-5">
                                <p className="font-weight-bold" style={{ fontSize: '1.5rem' }}>Our Solution</p>

                                <p className="text-secondary" style={{ fontSize: '1.15rem', lineHeight: '30px' }}>
                                    We stepped in with a standard curriculum of unique knowledge, crammed down into 2 days worth of interactive training session on everything FinTech, RegTech, InsurTech, and the must-knows of organizational success.
                                </p>

                                <p className="text-secondary" style={{ fontSize: '1.15rem', lineHeight: '30px' }}>
                                    To solve the second problem, we built a Learning Management System that automates the work of the HR department with regards to staff training. Our system was able to collate the data of staff to match their roles and competencies with necessary training; identity training intervention; keep records of acquired or pending training; and allow transparency on ‘who gets what training, when, where and why’.
                                </p>

                            </div>
                        </div>
                    </div>
                </div>


                <div className="container">
                    <div className="row pt-5 mt-5">
                        <div className="col-md-6 mx-auto">
                            <img src={TrainingMapImg} alt="Journey Map" className="img-fluid" />
                            <p className="text-secondary text-center mt-1">Training the staff on must-knows of organisational success</p>
                        </div>
                    </div>
                </div>

                <div className="my-5 py-5"></div>

                <div className="bg-impact-usecase">
                    <div className="container">
                        <div className="row pt-5 mt-5">
                            <div className="col-md-6 my-5">
                                <p style={{ fontSize: '1.5rem' }} className="font-weight-bold">The Impact</p>

                                <ul>
                                    <li><p style={{ fontSize: '1.15rem', lineHeight: '30px' }} className="text-secondary mb-1">Knowledge and skills development for market domination.</p></li>
                                    <li><p style={{ fontSize: '1.15rem', lineHeight: '30px' }} className="text-secondary mb-1">Increased productivity using technology. </p></li>
                                    <li><p style={{ fontSize: '1.15rem', lineHeight: '30px' }} className="text-secondary mb-1">Alleviated workload for the HR department.</p></li>
                                    <li><p style={{ fontSize: '1.15rem', lineHeight: '30px' }} className="text-secondary mb-1">Future-proofing for business continuity.</p></li>
                                </ul>


                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row py-5 my-5">
                        <div className="col-md-5 mx-auto mb-5 text-center">
                            <Link to={`/contact`} state={{ path: 'human' }}><button style={{ height: '48px' }} class="btn btn-red h-100 btn-red-black px-4 no-border-radius">Get started</button></Link>
                        </div>
                    </div>
                </div>
            </Layout >
        </div>
    )
}

export default CapacityBuildingUnilever
